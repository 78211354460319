<template>
    <div>
        <Toast position="bottom-right" />

        <!-- delete list dialog  -->
        <teleport to="body">
            <base-dialog :top="'30'" :width="'30'" :open="show_delete_scenario" @close="closeDeleteScenarioDialog()">
                <h3 class="dialog-header"> Delete list </h3>
                <div class="list-input-wrapper wwd-column">
                    <span class="font-2-basic dialog-body"> Are you sure to delete <span class="warning-color">{{chosed_scenario}}</span> scenario ?</span>
                    <div class="button-wrapper wwd-row">
                        <it-button type="success" class="create-list-button success-button" :loading="deleting_scenario" @click="deleteScenario()">Delete</it-button>
                        <it-button type="danger" class="create-list-button danger-button" @click="closeDeleteScenarioDialog()" >Cancel</it-button>
                    </div>
                </div>
            </base-dialog>
        </teleport>
        <!-- delete list dialog  -->
        <ScrollPanel class="signal-lists">
            <Card class="signal-lists base-card">
                <template #title>
                    <div class="wwd-row wwd-sb table-header">
                        <span> Scenarios </span>
                        <div class="wwd-row">
                            <span class="p-input-icon-left filter-input">
                                <i class="pi pi-search" />
                                <InputText type="text" v-model="filter_input" placeholder="Search" />
                            </span>
                            <Button v-tooltip="'Upload scenario as .json file'" label="Upload scenario" icon="pi pi-upload" class="upload-scenario-button p-button-raised p-button-success p-button-sm">
                                <input id="scenario-input" accept=".json" class="input" type="file" ref="scenario-file" @change="readFile" hidden>
                                <label class="label wwd-row wwd-sb" for="scenario-input"> <i class="pi pi-upload upload-icon"></i> Upload scenario </label>
                            </Button>
                        </div>
                    </div>
                </template>
                <template #content class="content">
                <DataTable  class="table" :value="dataFiltered" stripedRows responsiveLayout="scroll">
                    <Column field="name" header="Scenario"></Column>
                    <Column field="actions" header="Action">
                        <template #body="{data}">
                            <span>
                                <Button label="" icon="pi pi-download" class="download-button action-button p-button-raised p-button-info p-button-sm" @click="exportScneario(data.name)"/>
                                <Button label="" icon="pi pi-trash" class="action-button p-button-raised p-button-danger p-button-sm" @click="openDeleteScenarioDialog(data.name)"/>
                            </span>
                        </template>
                    </Column>
                </DataTable>
                </template>
            </Card>
        </ScrollPanel>
    </div>
</template>

<script>
    import BaseDialog from '@/components/ui_items/BaseDialog.vue'
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';
    import InputText from 'primevue/inputtext'
    import Card from 'primevue/card';
    import Button from 'primevue/button'
    import Toast from 'primevue/toast';
    
    import ScrollPanel from 'primevue/scrollpanel';

    export default {
        components: {
            BaseDialog,
            DataTable,
            Column,
            Card,
            Button,
            InputText,
            ScrollPanel,
            Toast
        },
        data() {
            return {
                data: [],
                deleting_scenario: false,
                show_delete_scenario: false,
                show_view_signals_dialog: false,    
                chosed_scenario: '',
                new_signal_names: null,
                filter_input: null
            }
        },
        methods: {
            async deleteScenario() {
                this.deleting_scenario = true

                await this.$store.dispatch('deleteSimulation', {
                    scenario_name: this.chosed_scenario,
                    vm: this
                })
                this.deleting_scenario = false
                await this.$store.dispatch('listOfSimulations')
                this.prepareData()
                this.closeDeleteScenarioDialog()
            },
            openDeleteScenarioDialog(scenario) {
                this.chosed_scenario = scenario
                this.show_delete_scenario = true
            },
            closeDeleteScenarioDialog() {
                this.show_delete_scenario = false
            },
            saveName(scenario) {
                this.chosed_scenario = scenario
            },
            async readFile(ev) {
                try {
                    const file = ev.target.files[0];
                    const name = ev.target.files[0].name.split('.')[0];
    
                    const scenario_reader = new FileReader();
                    let scenario = null;

                    
                    scenario_reader.onload = e =>  scenario = JSON.parse(e.target.result)
                    scenario_reader.readAsText(file);
                    scenario_reader.onloadend = async () => {
                                if (!scenario.grid_size || !scenario.elements) {
                                    this.$toast.add({severity:'error', summary: 'Error!', detail:`Invalid scenario file`, life: 5000});
                                    throw new Error('Invalid scenario file')
                                }
                                await this.$store.dispatch('uploadSimulation', {
                                scenario_name: name,
                                body: scenario,
                                vm: this
                            })
                            await this.$store.dispatch('listOfSimulations')
                            this.prepareData()
                        }
                    document.getElementById('scenario-input').value = null
                } catch(err) {
                    this.$toast.add({severity:'error', summary: 'Error!', detail:`Something went wrong`, life: 5000});
                }
            },
            prepareData() {
                const scenarios = this.$store.getters.listOfSimulations
                let scenarios_data = []
                for (const scenario in scenarios) {
                    scenarios_data.push({"name": scenario})
                }

                this.data = scenarios_data

            },
            download(content, fileName, contentType) {
                const a = document.createElement("a");
                const file = new Blob([content], { type: contentType });
                a.href = URL.createObjectURL(file);
                a.download = fileName;
                a.click();
            },
            async exportScneario(scenario_name) {
                const jsonData = await this.$store.dispatch('getScenarioConfiguration', {scenario_name: scenario_name})
                this.download(JSON.stringify(jsonData), `${scenario_name}.json`, "text/plain");
            },
            async getListOfSimulations() {
                await this.$store.dispatch('listOfSimulations')
                this.prepareData()
            }
        },
        computed: {
            buttonStyle() {
                return this.chosed_scenario === '' ? 'saved' : ''
            },
            isLoading() {
                return this.$store.getters.isLoading
            },
            scenariosList() {
                return this.$store.getters.listOfSimulations
            },
            dataFiltered() {
                return this.filter_input ? this.data.filter(element => element.name.toLowerCase().includes(this.filter_input.toLowerCase())) : this.data
            }
    
        },
        mounted() {
            this.getListOfSimulations()
        },
    }
</script>

<style scoped>
.signal-lists {
    height: 34em;
}
.table    {
    margin: 0 auto;
}
.label {
    position: relative;
    cursor: pointer;
    color: #ffffff;
    width: 100%;
    height: 100%;
}
.ico {
    font-size: 15px;
    margin-right: 2px;
    margin-top: 3px;
}
.list-input {
    width: 70%;
}
.button-wrapper {
    margin-top: 10px;
}
.create-list-button {
    margin-right: 5px;
}
.success-button {
    background-color: rgb(55, 143, 14);
    border-color: rgb(55, 143, 14);
    box-shadow: none;
}
.danger-button {
    background-color: rgb(196, 13, 0);
    border-color: rgb(196, 13, 0);
    box-shadow: none;
}
.action-button {
    margin: 0.1rem 0.2rem;
    height: 2rem !important;
    min-width: 3rem;
}
.upload-icon {
    margin-right: 10px;
}
.upload-scenario-button {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 10rem;
}
.dialog-header {
    color: var(--font1);
}
.dialog-body {
    color: var(--font1);
}
.download-button {
    background-color: var(--blue1) !important;
    border-color: var(--blue1) !important;
}
.table-header {
    display: flex;
    flex-wrap:wrap;
    gap: 0.5rem;
}
.filter-input {
    margin-right: 0.5rem;
}

</style>